import $ from "jquery";
import { replaceResponse } from './application';

let totalUploads = 0;
let totalUploadsCompleted = 0;
let fileMetadata = {};

function handleDirectUploadInitialize(event) {
  const product_color = document.getElementById('choosen_product_color').value
  const { target, detail } = event
  const { id, file } = detail

  document.getElementsByClassName('addcard')[0].insertAdjacentHTML("beforeend", `
    <div class="order__item cart z-top">
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}"
          class="direct-upload__progress"
          style="--cart-product-color-var: ${product_color};width: 0%" ></div>
        <div class="order__itemname">${file.name}</div>
      </div>
    </div>
  `)

  totalUploads++;

  if (file.type === 'application/pdf') {
    extractPdfMetadata(file).then(metadata => {
      console.dir('PDF METADATA ...');
      console.dir(metadata);
      fileMetadata[id] = metadata;
    }).catch(error => {
      console.error("Error extracting pdf metadata: ", error);
      fileMetadata[id] = {};
    });
  } else {
    extractImageMetadata(file).then(metadata => {
      console.dir('IMAGE METADATA ...');
      console.dir(metadata);
      fileMetadata[id] = metadata;
    }).catch(error => {
      console.error("Error extracting image metadata: ", error);
      fileMetadata[id] = {};
    });
  }
}

function handleDirectUploadStart(event) {
  $('#order_product_id').prop('disabled', 'disabled');
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
}

function handleDirectUploadProgress(event) {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
}

function handleDirectUploadError(event) {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
}

function handleDirectUploadEnd(event) {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")

  // Find the last hidden input field for the uploaded file
  let uploading_form = document.getElementById('upload__container');
  let inputs = uploading_form.querySelectorAll("input[type='hidden'][name='line_item[files][]']");
  let lastInput = inputs[inputs.length - 1];

  $.ajax({
    type: 'PUT',
    data: { blob_key: lastInput.value, metadata: JSON.stringify(fileMetadata[id]) },
    url: '/line_items/update_metadata',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // CSRF token for Rails
    },
    complete: function() {
      totalUploadsCompleted++;

      if (totalUploadsCompleted === totalUploads) {
        console.log("All uploads complete. Metadata for files:", fileMetadata);
        uploading_form.submit();
      }
    }
  });
}

async function extractPdfMetadata(file) {
  console.log('EXTRACTING PDF META DATA');

  let pdfData = new Uint8Array(await file.arrayBuffer());
  let loadingTask = pdfjsLib.getDocument({ data: pdfData });

  try {
    let pdfDocument = await loadingTask.promise;
    let page = await pdfDocument.getPage(1);
    let page_count = pdfDocument.numPages;
    let viewport = page.getViewport({ scale: 1 });
    let original_resolution = 72

    if (viewport.height && viewport.width) {
      return {
        original_height: viewport.height,
        original_width: viewport.width,
        inches_height: viewport.height / original_resolution,
        inches_width: viewport.width / original_resolution,
        original_resolution: original_resolution,
        type: 'pdf',
        page_count: page_count,
      };
    } else {
      console.log("Not all EXIF data is available");
      return {}
    }

  } catch (error) {
    console.error("Error extracting PDF metadata:", error);
    return {}
  }
}

async function extractImageMetadata(file) {
  console.log('EXTRACTING IMAGE META DATA');

  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const arrayBuffer = e.target.result;

        if (file.type === "image/tiff") {
          handleTiffImage(arrayBuffer, resolve, reject);
        } else {
          handleNonTiffImage(e.target.result, file.type, resolve, reject);
        }
      };

      reader.onerror = function () {
        console.error("Error occurred while reading the file with FileReader.");
        reject("Error reading file");
      };

      // Read as ArrayBuffer for TIFF and as DataURL for other images
      if (file.type === "image/tiff") {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("Error extracting IMAGE metadata:", error);
      reject(error);
    }
  });
}

function handleTiffImage(arrayBuffer, resolve, reject) {
  console.log("TIFF image detected. Extracting metadata using UTIF.js.");

  try {
    const ifds = UTIF.decode(arrayBuffer);
    console.log("IFDs parsed from TIFF file:", ifds);

    if (ifds.length === 0) {
      console.error("No IFDs found in the TIFF file.");
      resolve({});
      return;
    }

    const mainImage = ifds[0];
    console.log("Main IFD (Image Metadata):", mainImage);

    const width = mainImage.t256 ? mainImage.t256[0] : null;
    const height = mainImage.t257 ? mainImage.t257[0] : null;

    // Handle cases where t282 or t283 are single-element arrays
    const dpiX = mainImage.t282
      ? mainImage.t282[1]
        ? mainImage.t282[0] / mainImage.t282[1]
        : mainImage.t282[0] // Assume denominator is 1
      : 72; // Default to 72 DPI

    const dpiY = mainImage.t283
      ? mainImage.t283[1]
        ? mainImage.t283[0] / mainImage.t283[1]
        : mainImage.t283[0] // Assume denominator is 1
      : 72; // Default to 72 DPI

    if (width && height) {
      const inchesHeight = dpiY ? (height / dpiY).toFixed(2) : "N/A";
      const inchesWidth = dpiX ? (width / dpiX).toFixed(2) : "N/A";

      resolve({
        original_height: height,
        original_width: width,
        inches_height: inchesHeight,
        inches_width: inchesWidth,
        original_resolution: dpiX || dpiY || 72, // Default resolution
        original_resolution_x: dpiX,
        original_resolution_y: dpiY,
        type: 'tiff',
        page_count: 1, // ifds.length - intentionally left it to 1, though some tiff can have more then 1 page
      });
    } else {
      console.error("Metadata extraction failed for TIFF file: Missing width or height.");
      resolve({});
    }
  } catch (error) {
    console.error("Error decoding TIFF file:", error);
    reject("Error decoding TIFF file");
  }
}

function handleNonTiffImage(dataUrl, fileType, resolve, reject) {
  console.log("Processing non-TIFF image.");

  const img = new Image();

  img.onload = function () {
    console.log("Image loaded successfully.");

    EXIF.getData(img, function () {
      console.log('EXIF DATA EXTRACTION STARTS ...');

      let width = EXIF.getTag(this, "PixelXDimension") || EXIF.getTag(this, "ImageWidth");
      let height = EXIF.getTag(this, "PixelYDimension") || EXIF.getTag(this, "ImageHeight");
      let dpiX = EXIF.getTag(this, "XResolution");
      let dpiY = EXIF.getTag(this, "YResolution");

      console.log("Extracted EXIF Data:", { width, height, dpiX, dpiY });

      // Fallback to natural dimensions if EXIF data is unavailable
      if (!width || !height) {
        console.log("Falling back to natural dimensions.");
        width = img.naturalWidth;
        height = img.naturalHeight;
      }

      // Fallback DPI (default to 72 if missing)
      dpiX = dpiX || 72;
      dpiY = dpiY || 72;

      if (width && height && dpiX && dpiY) {
        const inchesHeight = (height / dpiY).toFixed(2);
        const inchesWidth = (width / dpiX).toFixed(2);

        resolve({
          original_height: height,
          original_width: width,
          inches_height: inchesHeight,
          inches_width: inchesWidth,
          original_resolution: dpiX,
          type: 'image',
          page_count: 1,
        });
      } else {
        console.error("Unable to extract dimensions or DPI.");
        resolve({});
      }
    });
  };

  img.onerror = function () {
    console.error("Error occurred while loading the image.");
    console.log("Image source:", img.src);
    console.log("File type:", fileType);
    reject("Error loading image: Unsupported or corrupted file.");
  };

  img.src = dataUrl;
}

document.addEventListener('turbo:load', function() {
  let uploading_form = document.getElementById('upload__container');

  if (uploading_form) {
    uploading_form.addEventListener('submit', function(event) {
      event.preventDefault();
    });
  }

  // Remove existing listeners
  document.removeEventListener("direct-upload:initialize", handleDirectUploadInitialize);
  document.removeEventListener("direct-upload:start", handleDirectUploadStart);
  document.removeEventListener("direct-upload:progress", handleDirectUploadProgress);
  document.removeEventListener("direct-upload:error", handleDirectUploadError);
  document.removeEventListener("direct-upload:end", handleDirectUploadEnd);

  // Add listeners
  document.addEventListener("direct-upload:initialize", handleDirectUploadInitialize);
  document.addEventListener("direct-upload:start", handleDirectUploadStart);
  document.addEventListener("direct-upload:progress", handleDirectUploadProgress);
  document.addEventListener("direct-upload:error", handleDirectUploadError);
  document.addEventListener("direct-upload:end", handleDirectUploadEnd);
});